 // Variable.scss

.nestable-cart {
    overflow: hidden;
}

// .dd-item > button {
//     height: 2.1rem;
// }
.dd-handle {
    color: $white;
    background: var(--primary);
    border-radius: 4px;
    border: 0;
	height: 45px;
    margin: 15px 0;
    padding: 13px 19px;
	    font-weight: 500;
	    font-size: 16px;
}

	.dd-item{
		button {
			margin: 12px 0;
			
		}
	}

.dd-item>button.dd-expand:before,
.dd-item>button.dd-collapse:before{
	color:$white;
}
.dd-handle:hover {
    color: $white;
    background: var(--primary);
}
// #nestable2 .dd-handle {
    // color: $white;
    // border: 1px solid $border;
    // background: var(--primary);
// }
// #nestable2 .dd-handle:hover {
//     background: var(--primary);
// }
// #nestable2 .dd-item>button:before {
//     color: $white;
// }
.dd3-content:hover {
    color: $white;
    background: var(--primary);
}
.dd3-content {
    color: $white;
}