[data-sidebar-position="fixed"][data-layout="vertical"] {
    .nav-header {
         position: fixed;
        // left: auto;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
	 
    .deznav {
        position: fixed;
        // z-index: 0;
        .deznav-scroll {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }

    .menu-toggle {
        .deznav {
            position: fixed;
        }
    }
}
[data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] {	
    .deznav {
        position: fixed;
		
    }
	.menu-toggle{
		.deznav {
			position: fixed;
			z-index: 99999;
			height: calc(100% - 90px);
			left: calc(calc(100% - 1199px) / 2);
			box-shadow: 20px 20px 20px 0 rgba(0, 0, 0, 0.1);
		}
	}

}


