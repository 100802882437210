@media (min-width:767px) {
    [data-sidebar-style="modern"] {

        .nav-header {
            width: 9.375rem;
			.brand-logo{
				    justify-content: center;
			}
        }
        .deznav {
        
            .metismenu {
				
                &>li {
                    text-align: center;
					
					
                    &>a {
                        padding: 20px 15px 20px 15px;
                        // font-weight: 500;

                        &::after {
                            display: none;
                        }

                        &:hover, 
                        &:focus, 
                        &:active, 
                        &.mm-active {
                            &>a {
                                background-color: lighten($color: $primary, $amount: 50%);
                            }
                        }
                    }
					&.mm-active{
						//padding:0;
						a{
							background-color:var(--primary);
							color:$white;
							border-radius:$radius;
						}
					}
                    li {
                        text-align: left;

                        @at-root [direction="rtl"]#{&} {
                            text-align: right;
                        }
                    }
                }

                li {

                    a {
                        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
                    }
					ul:after{
						content:none;
					}

                    &>ul {
                        height: auto !important;
                    }
                }
                .nav-label{
                    display: none;
                }
            }

            .nav-label {
                display: none;
            }
            

            .nav-text {
                display: block;
                margin-top: 0.3125rem;
            }
			.plus-box{
				display:none;
			}
			.copyright{
				display:none;
			}
        }

        .footer {
            padding-left: 9.375rem;
        }
        .content-body {
            margin-left: 9.375rem;
        }
    }

    [data-sidebar-style="modern"][data-layout="vertical"] {
		.header{
			.header-content{
				padding-left: 6.3125rem;
			}
		}
        .deznav {
            width: 9.375rem;
            left: 0;
			@at-root [direction="rtl"]#{&} {
				left: auto;
				right: 0;
			}  
			
            .slimScrollDiv, 
            .deznav-scroll {
                overflow: visible !important;
				padding: 0 0.875rem;
            }
        
            .metismenu {
                &>li {
                    &>a{
                        i{
                            font-size: 20px;
                            // height: 35px;
							padding-right:0;
                        }
                    }

                    &>ul {
                        display: none;
                        padding: 1.875rem 0.9375rem;
                    }
                }

                li {
                    position: relative;

                    a {
                        padding:0.938rem 1rem;
						&:before{
							content:none;
						}
                    }

                    ul {
                        position: absolute;
                        left: 105%;
                        top: 0;
                        bottom: auto;
                        background-color: $white;
                        border: 1px solid $border;
                        width: 200px;
                        // box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
						
						@at-root [data-theme-version="dark"]#{&} {
							background:$dark-card;
							box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
						}

                        @at-root [direction="rtl"]#{&} {
                            left: auto;
                            right: 105%;
                        }
                    }

                    &:hover {
                        &>ul {
							display: block;
							left: 110%;
							padding: 1rem 0;
							margin-left: -10px;
							border: 0;
							box-shadow: 5px 0px 13px 0px rgba(82, 63, 105, 0.05);
							border-radius: $radius;
							
							@at-root [data-theme-version="dark"]#{&} {
								box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
							}
							
							
                            @at-root [direction="rtl"]#{&} {
                                left: auto;
                                right: 100%;
								box-shadow: -5px 0px 13px 0px rgba(82, 63, 105, 0.05);
                            }  
                        }
                    }
					.mm-collapse{
						li{
							a{
								background-color:transparent;
								color:$side-text;
								@at-root [data-theme-version="dark"]#{&} {
									 color: rgba(255,255,255,0.7);
								}
							}
						}
					}
                }
            }
            

            .nav-label {
                display: none;
            }

            .nav-text {
                display: block;
                margin-top: 0;
            }
        }
		.nav-control{
			right: -5.0625rem;
		}

        .menu-toggle {
            .deznav {
                left: -9.375rem;

                @at-root [direction="rtl"]#{&} {
                    left: auto;
                    right: -9.375rem;
                }
            }

            .content-body {
                margin-left: 0;
				@at-root [direction="rtl"]#{&} {
                    margin-right: 0;
                }
            }
        }
    }

    [data-sidebar-style="modern"][data-layout="horizontal"] {
		.footer,
		.content-body {
			margin-left:0;
		}
		.nav-header {
			width: 16rem;
		}
        .deznav {
            .metismenu {
				padding: 0 30px;
				
                &>li {
                    &>a {
                        padding: 0.8125rem 2.25rem;
                    }

                    &>ul {
                        top: 5.5625rem;
                    }
					
					.mm-collapse{
						li{
							a{
								background-color:transparent;
							}
						}
					}
                }
			
            }
        }
		
        &[data-container="boxed"] {
            .deznav {
                .metismenu {
    
                    &>li {
                        &>a {
                            padding: 0.8125rem 1.25rem;
                        }
                    }
                }
            }
        }
    }
}