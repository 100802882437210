[data-sidebar-style="overlay"] {
    .deznav {
        left: -100%;
        @at-root [direction="rtl"]#{&} {
            left: auto;
            right: -100%;
        }
    }
    .content-body {
        margin-left: 0;
    }
    .nav-header {
        position: absolute;
        .hamburger.is-active {
            left: 0;
            .line {
                background-color: var(--primary);
            }
        }
    }
    .menu-toggle {
        .nav-header {
            position: absolute;
            left: auto;
        }
        .deznav {
            left: 0;
            @at-root [direction="rtl"]#{&} {
                left: auto;
                right: 0;
            }
        }
    }
    .footer {
        padding-left: 0;
    }
}

[data-sidebar-style="overlay"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}

[data-sidebar-position="fixed"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}
[data-container="wide-boxed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"]{ 
	.deznav {
        position: fixed;
		
    }
	.menu-toggle{
		.deznav {
			position: fixed;
			z-index: 99999;
			height: calc(100% - 90px);
			left: calc(calc(100% - 1480px) / 2);
			box-shadow: 20px 20px 20px 0 rgba(0, 0, 0, 0.1);
		}
	}	 
		
}