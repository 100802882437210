.bg-image{
	z-index:1;
	&:after{
		position:absolute;
		content:"";
		background-image:url('../images/dolor.png');
		height:150px;
		width:100%;
		background-repeat:no-repeat;
		background-repeat: no-repeat;
		background-position: bottom right;
		bottom: -30px;
		z-index:-1;
	}
}
.bg-image-2{
	z-index:1;
	&:after{
		position:absolute;
		content:"";
		background-image:url('../images/ticket.png');
		height:150px;
		width:100%;
		background-repeat:no-repeat;
		background-repeat: no-repeat;
		background-position: bottom right;
		bottom: -30px;
		z-index:-1;
	}
}
.testimonial-one{
	.owl-nav{
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		bottom: 24px;
		font-size:27px;
		color:$white;
		padding: 0 30px;
	}
	span{
		position: absolute;
		bottom: 35px;
		left: 50%;
		color:$white;
		z-index: 1;
		transform: translateX(-50%);
	}
	.image{
		&:after{
			position:absolute;
			bottom:0;
			left:0;
			height:100%;
			width:100%;
			content:"";
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,000000+100&0+64,0.7+100 */
			background: -moz-linear-gradient(top,  rgba(30,87,153,0) 0%, rgba(11,31,55,0) 64%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(30,87,153,0) 0%,rgba(11,31,55,0) 64%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(30,87,153,0) 0%,rgba(11,31,55,0) 64%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#b3000000',GradientType=0 ); /* IE6-9 */
		}
	}
}